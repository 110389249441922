import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import School from './pages/school.tsx'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Testen from './pages/testen.tsx';
import OpvoerenWoord from './pages/opvoeren-woord.tsx';
import OpvoerenBoek from './pages/opvoeren-boek.tsx';

const router = createBrowserRouter([
  {
    path: "/",
    element: <School />,
    children: [
      {
        path: "testen",
        element: <Testen />,
      },
      {
        path: "opvoeren-woord",
        element: <OpvoerenWoord />,
      },
      {
        path: "opvoeren-boek",
        element: <OpvoerenBoek />,
      },
  ]
  }
]);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
)
