/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from "mobx";
import SchoolApi from "../api/school-api";
import { Boek } from "../model/boek";
import { Vertaling } from "../model/vertaling";

class TaalStore {
    private _vertalingen: Vertaling[] = [];
    private _teTestenVertaling: Vertaling|null;
    private _boekSecties: any[] = [];
    private _api: SchoolApi;
    private _rerender: boolean = false;
    private _opvoerNL: string = "";
    private _currentBoek: Boek = {};
    private _selectedBoekSectieId = 0;
    private _boekPagina: string = "";
    private _boekSectie: string = "";
    private _opvoerAnders: string = "";
    private _boeken: Boek[] = [];

    constructor(api: SchoolApi) {
        this._vertalingen = [];
        this._boekSecties = [];
        this._teTestenVertaling = null;
        this._boeken = [];
        this._api = api;
        this._currentBoek = new Boek();
        this._boekPagina = "";
        this._boekSectie = "";
        this._rerender = true;
        this._opvoerAnders = "";
        this._opvoerNL = "";
        this._selectedBoekSectieId = 0;
        makeAutoObservable(this);
        this.init();
    }

    public get teTestenVertaling (): Vertaling|null {
        return this._teTestenVertaling;
    }

    public set teTestenVertaling (value: Vertaling|null) {
        runInAction(() => {
            this._teTestenVertaling = value;
        });
    }

    public get boekSecties () {
        return this._boekSecties;
    }

    public set boekSecties (value) {
        runInAction(() => {
            this._boekSecties = value;
        })
    }

    public get selectedBoekSectieId () {
        return this._selectedBoekSectieId;
    }

    public set selectedBoekSectieId (value) {
        runInAction(() => {
            this._selectedBoekSectieId = value;
            this.teTestenVertaling = this.vertalingen.find(v => v.resultaat !== 1 && v.boekSectieId == value) ?? null;
        });
    }

    public get opvoerNL () {
        return this._opvoerNL;
    }

    public set opvoerNL (value) {
        runInAction(() => {
            this._opvoerNL = value;
        })
    }

    public get currentBoek (): Boek {
        return this._currentBoek;
    }

    public set currentBoek (value: Boek) {
        runInAction(() => {
            this._currentBoek = value;
        });
    }

    public get boekPagina () {
        return this._boekPagina;
    }

    public set boekPagina (value) {
        runInAction(() => {
            this._boekPagina = value;
        })
    }

    public get boekSectie () {
        return this._boekSectie;
    }

    public set boekSectie (value) {
        runInAction(() => {
            this._boekSectie = value;
        })
    }

    public get opvoerAnders () {
        return this._opvoerAnders;
    }

    public set opvoerAnders (value) {
        runInAction(() => {
            this._opvoerAnders = value;
        })
    }

    public get rerender () {
        return this._rerender;
    }

    public set rerender (value) {
        runInAction(() => {
            this._rerender = value;
        });
    }

    public get vertalingen () {
        return this._vertalingen;
    }

    public get vertalingenTeTesten () {
        return this._vertalingen.filter(v => v.resultaat !== 1 && v.boekSectieId === this._selectedBoekSectieId);
    }

    public set vertalingen (value) {
        runInAction(() => {
          this._vertalingen = value;
        });
    }

    public get boeken () {
        return this._boeken;
    }

    public set boeken (value) {
        runInAction(() => {
          this._boeken = value;
        });
    }

    public init = () => {
        this._api.getVertalingen()
        .then(res => {
            this.vertalingen = res.map((r:any) => { return { ...r, resultaat: 0, controleTekst: ""}});
            this.teTestenVertaling = this.vertalingen.find(v => v.resultaat == 0 && v.boekSectieId == this._selectedBoekSectieId) ?? null;
        });
        this._api.getBoeken()
        .then((res) => {
            this.boeken = res;
        });
        this._api.getBoekSecties()
        .then(res => {
            this.boekSecties = res.map((r:any) => { return { id: r.id, label: `${r.boekTaal} - ${r.boekTitel}: ${r.bladzijde} - ${r.sectie}`}});
            this.selectedBoekSectieId = this.boekSecties.find(f => f).id;
        })
    }

    public resetTest = () =>{
        this.vertalingen.forEach(v => v.resultaat = 0);
        this.vertalingen = this.vertalingen.slice();
        this.teTestenVertaling = this.vertalingen.find(v => v.resultaat == 0 && v.boekSectieId == this._selectedBoekSectieId) ?? null;
    }
    public opslaan = () => {
        console.log('Opslaan store', this.opvoerNL, this.opvoerAnders, this.selectedBoekSectieId);
        this._api.opslaan(this.opvoerNL, this.opvoerAnders, this.selectedBoekSectieId)
        .then(res => {
            this.vertalingen = res.map((r: any) => { return { ...r, resultaat: 0, controleTekst: ""}});
            this.opvoerNL = "";
            this.opvoerAnders = "";
        })
    }

    public opslaanBoek = async () => {
        console.log('Opslaan boek', this.currentBoek.taal, this.currentBoek.titel);
        const res = await this._api.opslaanBoek(this.currentBoek);
        this.boeken = res;
        this.currentBoek = {};
    }

    public opslaanBoekSectie = () => {
        console.log('Opslaan boek sectie', this.currentBoek.id, this.boekPagina, this.boekSectie);
        this._api.opslaanBoekSectie(this.currentBoek.id ?? 0, this.boekPagina, this.boekSectie)
        .then(res => {
            this.boeken = res;
            this.currentBoek = {};
        })
    }

    public checkResultaat = (id: number, controleTekst: string) => {
        runInAction(() => {
            const vertaling = this.vertalingen.find(v => v.id == id) ?? new Vertaling();
            vertaling.controleTekst = controleTekst;
            if (vertaling.anders === controleTekst) {
                vertaling.resultaat = 1;
            } else {
                vertaling.resultaat = -1;
            }
            this.vertalingen = this.vertalingen.slice();
            const vertalingenToCheck = this.vertalingen.filter(v => v.resultaat !== 1);
            this.teTestenVertaling = vertalingenToCheck[Math.floor(vertalingenToCheck.length* Math.random()| 0)] ?? null;
        });
    }

    public changeControleTekst = (newTekst: string, id: number) => {
        console.log(id);
        runInAction(() => {
            const vertaling = this.vertalingen.find(v => v.id == id);
            if (vertaling) {
                vertaling.controleTekst = newTekst;
            }
            this.vertalingen = this.vertalingen.slice();
        });
    }
}

export default TaalStore;
