import { Card } from "primereact/card";
import Digraph from "../compenents/digraph";
import { observer } from "mobx-react-lite";
import { useContext, useRef } from "react";
import storeContext from "../store/taal-context";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";

const OpvoerenWoord = observer(() => {

  const store = useContext(storeContext);
  const toast = useRef<Toast>(null);
  const digraphRef = useRef<AutoComplete>(null);

  const opslaan = () => {
    store.opslaan();
    toast.current?.show({ severity: 'info', content: 'Vertaling opgeslagen'});
    digraphRef.current?.focus();
  }

  const disabled = !store.opvoerNL || !store.opvoerAnders;

  return <Card className="flex" title="Opvoeren vertaling">
    <Toast ref={toast} />
    <div className="field">
      <Dropdown options={store.boekSecties} placeholder="Selecteer een boek en sectie" optionValue="id" optionLabel="label" value={store.selectedBoekSectieId} onChange={(e:any) => { store.selectedBoekSectieId = e.target.value }} />
    </div>
    <div className="field">
      <Digraph ref={digraphRef} value={store.opvoerNL} id="nl" label="Nederlands" onChange={(e:any) => store.opvoerNL = e.target.value} />
    </div>
    <div className="field">
      <Digraph value={store.opvoerAnders} id="duits" label="Duits" onChange={(e:any) => store.opvoerAnders = e.target.value} />
    </div>
    <div className="field">
      <Button icon="pi pi-save" raised label="Opslaan" disabled={disabled} onClick={opslaan} />
    </div>
  </Card>
});

export default OpvoerenWoord;