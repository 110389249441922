import { forwardRef, useState } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";

const Digraph = forwardRef((props: any, ref: any) => {

  const [ suggestions, setSuggestions ] = useState<string[]>([]);

  const aanvullen = (event: AutoCompleteCompleteEvent) => {
    const laatsteLetter = event.query.substring(event.query.length - 1);
    const mogelijkheden = [ event.query];
    const begin = event.query.substring(0, event.query.length - 1);
    switch (laatsteLetter) {
      case "a": {
        mogelijkheden.push(begin + "ä")
        break;
      }
      case "A": {
        mogelijkheden.push(begin + "Ä")
        break;
      }
      case "e": {
        mogelijkheden.push(begin + "ë")
        mogelijkheden.push(begin + "é")
        mogelijkheden.push(begin + "è")
        break;
      }
      case "E": {
        mogelijkheden.push(begin + "Ë")
        mogelijkheden.push(begin + "É")
        mogelijkheden.push(begin + "È")
        break;
      }
      case "o": {
        mogelijkheden.push(begin + "ö")
        break;
      }
      case "O": {
        mogelijkheden.push(begin + "Ö")
        break;
      }
      case "u": {
        mogelijkheden.push(begin + "ü")
        break;
      }
      case "U": {
        mogelijkheden.push(begin + "Ü")
        break;
      }
    }
    setSuggestions(mogelijkheden);
  }

  const usedClass = props.label ? "p-float-label mt-5" : "p-float-label";

  return (
    <span className={usedClass}>
      <AutoComplete ref={ref} {...props} suggestions={suggestions} completeMethod={aanvullen} />
      { props.label &&
        <label htmlFor={props.id}>{props.label}</label>
      }
    </span>
  );
});


export default Digraph
