import { Dropdown } from "primereact/dropdown"

const talen = [
  { name: "-", code: null},
  { name: "Duits", code: "DUITS"},
  { name: "Engels", code: "E"},
  { name: "Frans", code: "F"},
  { name: "Grieks", code: "G"},
  { name: "Latijn", code: "L"},
  { name: "Nederlands", code: "NL"},
];

const SelectTaal = (props: any) => {
  return <Dropdown { ...props } onChange={props.onChange} options={talen} optionLabel="name" optionValue="code">
  </Dropdown>
}

export default SelectTaal;