export class Vertaling {
  id?: number;
  nl?: string = "Hahaha";
  anders?: string;
  boekSectieId?: number;
  resultaat?: number;
  controleTekst?: string;

  constructor () {
  }
}