import { observer } from "mobx-react-lite";
import React, { useContext, useRef, useState } from "react";
import storeContext from "../store/taal-context";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable, DataTableExpandedRows, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import SelectTaal from "../compenents/select-taal";
import { Boek } from "../model/boek";

const OpvoerenBoek = observer(() => {

  const store = useContext(storeContext);
  const toast = useRef<Toast>(null);
  const [ expandedRows, setExpandedRows ] = useState<DataTableExpandedRows|DataTableValueArray|undefined>(undefined);

  const opslaanBoek = async () => {
    await store.opslaanBoek();
    toast.current?.show({ severity: 'info', content: 'Boek opgeslagen'});
    setBoekopvoeren(false);
  }

  const opslaanBoekSectie = () => {
    store.opslaanBoekSectie();
    toast.current?.show({ severity: 'info', content: 'Sectie opgeslagen'});
    setBoekopvoeren(false);
  }

  const boekOpvoerenAanzetten = () => {
    store.currentBoek = new Boek();
    setBoekopvoeren(true);
  }
  const headerBoek = () => {
    return <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold" >Boeken</span>
      <Button className="pi pi-plus" rounded raised onClick={boekOpvoerenAanzetten}></Button>
    </div>
  }

  const headerBoekSectie = (data: any) => {
    return <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold" >Boek sectie</span>
      <Button className="pi pi-plus" rounded raised onClick={() => { store.currentBoek = data; setBoekSectieopvoeren(true); }}></Button>
    </div>
  }

  const [ boekOpvoeren, setBoekopvoeren ] = useState<boolean>(false);
  const [ boekSectieOpvoeren, setBoekSectieopvoeren ] = useState<boolean>(false);

  const taalTemplate = (data: any) => {
    return <SelectTaal disabled value={data.taal} />;
  }

  const allowExpansion = (_rowData: any) => {
    return true; // rowData.sectie?.length >= 0;
  }

  const rowExpansionTemplate = (data: any) => {
    return <DataTable
        value={data.sectie}
        header={() => headerBoekSectie(data)}>
      <Column field="bladzijde" header="Bladzijde" />
      <Column field="sectie" header="Sectie" />
    </DataTable>
  }

  const editBoek = (rowData: any) => {
    return <Button icon="pi pi-pencil" onClick={() => {
      store.currentBoek = rowData;
      setBoekopvoeren(true);
    }}/>
  }

  return <React.Fragment>
    <Toast ref={toast} />
    <DataTable
        value={store.boeken}
        header={headerBoek}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}>
      <Column expander={allowExpansion} style={{ width: "5em" }} />
      <Column field="titel" header="Titel" />
      <Column body={taalTemplate} header="Taal" />
      <Column body={editBoek} />
    </DataTable>
    <Dialog header="Boek" visible={boekOpvoeren} onHide={() => { setBoekopvoeren(false)}}>
      <div className="field">
        <label htmlFor="boekTitel">Titell</label>
        <InputText className="ml-2" id="boekTitel" value={store.currentBoek.titel || "" } onChange={(e:any) => store.currentBoek = { ...store.currentBoek, titel: e.target.value}} />
      </div>
      <div className="field">
        <label htmlFor="boekTaal">Taal</label>
        <SelectTaal className="ml-2" id="boekTaal" value={store.currentBoek.taal} onChange={(e:any) => store.currentBoek = { ...store.currentBoek, taal: e.target.value}} />
      </div>
      <div className="field">
        <Button icon="pi pi-save" raised label="Opslaan" disabled={!store.currentBoek.titel || !store.currentBoek.taal} onClick={opslaanBoek} />
      </div>
    </Dialog>
    <Dialog header="Boek sectie" visible={boekSectieOpvoeren} onHide={() => { setBoekSectieopvoeren(false)}}>
      <div className="field">
        <InputText value={store.boekPagina} onChange={(e:any) => store.boekPagina = e.target.value} />
      </div>
      <div className="field">
        <InputText value={store.boekSectie} onChange={(e:any) => store.boekSectie = e.target.value} />
      </div>
      <div className="field">
        <Button icon="pi pi-save" raised label="Opslaan" disabled={!store.boekPagina || !store.boekSectie} onClick={opslaanBoekSectie} />
      </div>
    </Dialog>
  </React.Fragment>
});

export default OpvoerenBoek;
