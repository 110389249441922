import axios from "axios";
import { Boek } from "../model/boek";

export default class SchoolApi {

    constructor () {};

    async getVertalingen ()  {
        return await axios.get("./api/vertaling/d")
        .then(res => {
            return res.data;
        });
    }

    async getBoeken ()  {
        return await axios.get("./api/boeken")
        .then(res => {
            return res.data;
        });
    }

    async getBoekSecties ()  {
        return await axios.get("./api/boeken/sectie/alle")
        .then(res => {
            return res.data;
        });
    }

    async opslaan (nl: string, anders: string, boekSectieId: number)  {
        console.log("Opslaan api", nl, anders);
        return await axios.post("./api/vertaling/save/d", { nl, anders, boekSectieId })
        .then(res => {
            return res.data;
        });
    }

    async opslaanBoek (boek: Boek)  {
        console.log("Opslaan boek", boek.taal, boek.titel);
        let res = await axios.post("./api/boeken/opslaan", { id: boek.id, titel: boek.titel, taal: boek.taal });
        return res.data;
  }

    async opslaanBoekSectie (boekId: number, pagina: string|null, sectie: string|null)  {
        console.log("Opslaan boek sectie", boekId, pagina, sectie);
        return await axios.post("./api/boeken/sectie/opslaan", { boekId: boekId, bladzijde: pagina, sectie: sectie })
        .then(res => {
            return res.data;
        });
    }
}
