import { Menubar } from "primereact/menubar";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const School = () => {
  const navigate = useNavigate();
  const items = [
    {
      label: "Testen",
      icon: "pi pi-question",
      command: () => { navigate("/testen")},
    },
    {
      label: "Opvoeren Woord",
      icon: "pi pi-pencil",
      command: () => { navigate("/opvoeren-woord")},
    },
    {
      label: "Opvoeren Boek",
      icon: "pi pi-book",
      command: () => { navigate("/opvoeren-boek")},
    },
  ];

  return (
    <React.Fragment>
      <Menubar model={items} />
      <Outlet />
    </React.Fragment>
  );
}

export default School;
